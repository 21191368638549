<template>
  <div class="logo-chart">
    <!-- Header -->
    <div
      slot="header"
      class="logo-chart-header card-title"
      :style="type === 'alt' ? { minHeight: '7rem' } : null"
    >
      <!-- Sum -->
      <div
        v-if="!hideTotal"
        class="list-sum"
      >
        TOTAL:

        {{ valuesSum | formatLocale }}
      </div>

      <!-- Title -->
      <div class="logo-chart-header--title">
        <span
          v-if="type === 'alt'"
          class="m-0"
        >
          {{ title }}
        </span>
        <span
          v-else
          class="m-0"
        >
          {{ title.toUpperCase() }}
        </span>

        <div class="logo-chart-header--actions">
          <template v-if="listData.length > 0">
            <vs-tooltip
              delay=".4s"
              :text="'Ordenar Por: ' + (sortBy === 'value' ? 'Valor' : 'Nome')"
            >
              <button
                class="sort-list-btn"
                @click="toggleSort"
              >
                <i class="mdi mdi-sort" />
              </button>
            </vs-tooltip>
          </template>

          <vs-tooltip
            delay=".4s"
            :text="checkItemAccess('LGPD') ? 'Exportar: ' + title : 'Necessário permissão para exportar!'"
            :style="checkItemAccess('LGPD') ? '' : 'opacity:0.35;'"
          >
            <button
              class="sort-list-btn"
              style="margin-left:10px;"
              :disabled="checkItemAccess('LGPD') ? false : true"
              @click="exportIndicatorValue"
            >
              <i class="mdi mdi-download" />
            </button>
          </vs-tooltip>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="logo-chart-content">
      <ul class="logo-chart-list">
        <li
          v-for="(item, itemIdx) in listData"
          :key="itemIdx"
          class="logo-chart-list--item"
        >
          <div>
            <img
              v-if="indicator"
              class="c-image"
              :src="getImagePath(item.text)"
              alt="logo"
              @error="getFallbackPath"
            >
          </div>

          <div class="card-info-main">
            <div class="info-card-img">
              <p
                class="text-info"
                style="font-size:0.9rem;"
              >
                {{ item.text.replace("otherText=", "") }}
              </p>
            </div>

            <div class="d-flex flex-column align-items-center info-card-text">
              <div class="percent-text">
                <small class="mb-1">
                  <p
                    style="font-size:0.9rem;"
                    class="text-value"
                  >
                    {{ item.value }}
                  </p>
                </small>

                <span
                  style="font-size:0.8rem; padding-top:7px;"
                  class="c-badge badge-pill badge badge-dark text-white text-percentage"
                >
                  {{ item.perc | formatNumber }}%
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

export default {
  name: 'LogoChart',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    indicator: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    listData: [],
    images: [],
    sortBy: 'value',
  }),
  computed: {
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    hideTotal() {
      const visibility = this.$store.state.visibilityOptions;

      return (
        Object.keys(visibility).length > 0
        && visibility.hideChartTotal
      );
    },
    valuesSum() {
      return this.listData.reduce((a, b) => a + b.value, 0);
    },
  },
  created() {
    this.listData = [...this.data];
    this.getImages();
  },
  methods: {
    /* API */
    async getImages() {
      this.images = await systemService.getImages();
    },
    getImagePath(fileName) {
      return systemService
        .getImagePath(
          this.images,
          fileName,
          this.indicator
        );
    },
    getFallbackPath(evt) {
      evt.target.src = `${configDomain}/images/api/outro.png`;
    },
    exportIndicatorValue() {
      surveyService.exportIndicatorValue(
        this.indicator,
        this.$store.state.configBox.nouns.prj,
        this.title.replaceAll(' ', '_'),
        this.token,
      )
        .then((res) => (this.downloadCSV(res, this.title)))
        .catch((err) => alert(err));
    },
    downloadCSV(content, fileName) {
      const csvData = new Blob([content], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.setAttribute('download', fileName);
      link.click();
    },
    checkItemAccess(access) {
      if (!access) return true;

      if (this.$store.state.loginData.accessValues.includes(access)) {
        return true;
      }

      return false;
    },
    /* Chart */
    toggleSort() {
      if (this.sortBy === 'value') {
        this.sortBy = 'name';

        // Sort by Indicator Name
        const updated = [...this.data];

        this.listData = updated.sort((a, b) => a.text.localeCompare(b.text));
      } else if (this.sortBy === 'name') {
        this.sortBy = 'value';
        this.listData = [...this.data];
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .logo-chart-header--actions {
    bottom: 20px;
    display: flex;
    position: relative;
    top: 0;
  }

.logo-chart {
  background-color: white;
  border: 0;
  border-radius: .3rem;
  display: flex;
  flex-flow: column nowrap;
  height: 500px;

  /* Header */
  .logo-chart-header {
    background-color: rgba(0, 0, 0, .64);
    border: 0;
    border-radius: .3rem .3rem 0 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.1rem;
    padding: 0.8rem 1rem;

    .logo-chart-header--title {
      align-items: baseline;
      color: #FAFAFA;
      display: flex;
      font-size: 1em !important;
      font-weight: 500;
      gap: 0.5rem;
      justify-content: space-between;
      letter-spacing: 0.25px;
      -webkit-font-smoothing: antialiased;

      .sort-list-btn {
        align-items: center;
        background: none;
        background-color: rgba(255, 255, 255, 0.120);
        border: none;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: none;
        height: 1.5rem;
        width: 1.5rem;

        i {
          color: white;
          font-size: 1.07em;
          max-height: 22.74px;
        }
      }
    }

    .list-sum {
      align-items: baseline;
      justify-content: space-between;
      display: flex;
      flex-flow: row nowrap;
      color: #d6d3d1;
      font-size: 0.75rem !important;
      letter-spacing: 0.4px;
      white-space: nowrap;
    }
  }

  /* Content */
  .logo-chart-content {
    overflow: auto;
    padding: 0 1rem 1rem;

    .logo-chart-list {
      list-style: none;
      margin-top: 1rem;
      padding-left: 0;

      .logo-chart-list--item {
        align-items: center;
        display: flex;
        margin-bottom: 1.5rem;
        padding: .5rem;
      }

      .card-info-main {
        display: flex;
        width: 100%;

        .info-card-img {
          margin-left: 18px;
          width: 93%;
        }

        .info-card-text {
          width: 60px;
        }

        .text-info {
          position: relative;
          top: 8px;
          color: #2b2b2b !important;
        }

        .percent-text {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: -5px;
          width: 60px;
        }

        .text-percentage {
          color: white;
          font-size: 0.8rem;
        }

        .text-value {
          color: black;
          font-size: 0.8rem;
          font-weight: bold;
          height: 0;
        }
      }
    }
  }
}

/* Custom Badge */
.c-badge {
  height: 26px !important;
}

/* Custom Image */
.c-image {
  height: 40px;
  width: 40px;
  margin-left: -5px;
  object-fit: contain;
  object-position: center center;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
</style>
